<template>
    <!--查体-->
    <div>
        <el-dialog :title="operation ? '添加' : (optType?'编辑':'查看')" :visible.sync="dlgVisible" v-model="dlgVisible"
                   :close-on-click-modal="false">
            <el-form ref="dlgFrom" :model="dlgFrom" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="UserName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="dlgFrom.UserName" :disabled="true">
                                <el-button slot="append" icon="el-icon-search" @click="chooseUser" v-if="operation"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="公司:" prop="Factory">
                            <el-input type="text" v-model="dlgFrom.Factory" :disabled="true"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="部门:" prop="DptName">
                            <el-input type="text" v-model="dlgFrom.DptName" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="岗位:" prop="PostName">
                            <el-input type="text" v-model="dlgFrom.PostName" :disabled="true"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="体检时间:" prop="CheckupDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-date-picker type="date" v-model="dlgFrom.CheckupDate" :disabled="!optType" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="体检类型:" prop="Category" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.Category" placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="岗前体检" value="岗前体检"></el-option>
                                <el-option label="岗中体检" value="岗中体检"></el-option>
                                <el-option label="转岗体检" value="转岗体检"></el-option>
                                <el-option label="离岗体检" value="离岗体检"></el-option>
                                <el-option label="个人体检" value="个人体检"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="体检结果:" prop="Result">
                            <el-switch v-model="dlgFrom.Result" active-color="#67c23a" inactive-color="#e6a23c" active-text="正常" inactive-text="异常" :disabled="!optType"> </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="体检描述">
                            <el-input type="textarea" v-model="dlgFrom.Describe" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否复查:" prop="ReResult">
                            <el-switch v-model="dlgFrom.ReResult" active-text="是" inactive-text="否" :disabled="!optType"> </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="复查结果:" prop="ReviewResult">
                            <el-switch v-model="dlgFrom.ReviewResult" active-text="异常" inactive-text="正常" :disabled="!optType"> </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="复查描述">
                            <el-input type="textarea" v-model="dlgFrom.ReDescribe" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="处理结果">
                            <el-input type="textarea" v-model="dlgFrom.ReviewResult2" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <upload-files :files="dlgFrom.Files" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles"
                              :IsDisabled="optType" :IsDel="optType"></upload-files>
                <el-row v-if="optType">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('dlgFrom')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
            <UserChoose :data="choosedusers" :all="true" :single="false" @callback="checkupUsercallFunction"></UserChoose>
        </el-dialog>
    </div>
</template>

<script>
    import UploadFiles from "../../components/UploadFiles";
    import UserChoose from "../../components/UserChoose";
    import {
        AddCheckupInfo,
        GetCheckupInfo,
        UpdateCheckupInfo,
        GetUserPostDptOrg
    } from "../../api/hr";
    export default {
        components: { UploadFiles, UserChoose },
        data() {
            return {
                optType: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑
                key: 0,

                dlgVisible: false, // dlg 显示隐藏
                dlgFrom: {
                    Factory: "",
                    DptName: "",
                    PostName:""
                },

                addUserVisible: false,
                choosedusers: [],
            };
        },
        methods: {
            // 选中一行显示
            onShowDlg(pid) {
                if (pid == "" || pid == null) {
                    this.dlgFrom = {
                        Factory: "",
                        DptName: "",
                        PostName: ""};
                    this.key += 1;
                    this.dlgVisible = true;
                }
                else {
                    //console.log("this", this)
                    GetCheckupInfo({ id: pid }).then((res) => {
                        this.dlgFrom = res.data.response;
                        this.key += 1;
                        GetUserPostDptOrg({ userId: this.dlgFrom.UserId }).then((res) => {
                            if (res.data.success) {
                                this.dlgFrom.Factory = res.data.response.OrganizationName;
                                this.dlgFrom.DptName = res.data.response.DepartmentName;
                                this.dlgFrom.PostName = res.data.response.PostName;
                                // this.dlgFrom.DptId = res.data.response[0].DepartmentId;
                                // this.dlgFrom.JobType = res.data.response[0].PostWorkTypeId;
                                this.dlgVisible = true;
                            } else {
                                this.$message({
                                    message: "获取部门信息出错",
                                    type: "error",
                                    duration: 5000,
                                });
                                this.dlgVisible = true;
                            }
                        });
                    });
                    
                    //this.dlgVisible = true;
                }
            },
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.dlgFrom);
                            if (this.operation) {
                                //新增
                                AddCheckupInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            } else {
                                //修改
                                UpdateCheckupInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "修改成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            }
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },

            // uploadFile Fucation
            dealFiles(data) {
                this.dlgFrom.Files = data.backData;
            },
            // AddUser Fucation
            checkupUsercallFunction(newdata) {
                if (newdata.length > 1) {
                    this.$message({
                        message: "不允许选择多人",
                        type: "error",
                    });
                    return;
                }
                this.dlgFrom.UserId = newdata[0].Id;
                this.dlgFrom.UserName = newdata[0].UserNameClaim;
                GetUserPostDptOrg({ userId: newdata[0].Id }).then((res) => {
                    if (res.data.success) {
                        this.dlgFrom.Factory = res.data.response.OrganizationName;
                        this.dlgFrom.DptName = res.data.response.DepartmentName;
                        this.dlgFrom.PostName = res.data.response.PostName;
                        // this.dlgFrom.DptId = res.data.response[0].DepartmentId;
                        // this.dlgFrom.JobType = res.data.response[0].PostWorkTypeId;

                    } else {
                        this.$message({
                            message: "获取部门信息出错",
                            type: "error",
                            duration: 5000,
                        });

                    }
                });
                this.addUserVisible = false;
            },
            chooseUser() {
                this.addUserVisible = true;
            },
        },
        mounted() {
            
        },
    };
</script>

<style scoped>
</style>